import { useEffect, useState } from 'react'
import fbAuth from '@apis/firebase'
import { useRouter } from 'next/router'

export default function useIsUserLogged() {
  const router = useRouter()
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const [isFBLoading, setIsFBLoading] = useState<boolean>(true)

  useEffect(() => {
    if (router.isReady) {
      fbAuth.onAuthStateChanged(async (user) => {
        if (user) {
          setIsLogged(true)
        }
        setIsFBLoading(false)
      })
    }
  }, [router])

  return { isFBLoading, isLogged }
}
