import convertArrayToObject from '../_utilities/convert-array-to-object'
import { filter, flatten, map, pipe, prop } from 'ramda'
import type { RailContent, RailEntity } from '@apis/rails-microservice/_types'
import type {
  Bundle,
  ContentEntity,
  UserContentState,
} from '@apis/contents-microservice/_entities/_types'
import type { RailsDictionaryEntry } from '../rails-slice/_types'
import type { ContentsState } from './_types'
import { ResumePlayback } from '@apis/analytics-microservice/_entities/resume-playback'
import { ContentResourceInterface } from '@shared/interfaces/assets/content-resource.interface'
import { isEmptyArray } from '@root/utils/arrays'
import { FeaturedContentReducerInterface } from './_types'
import { RecommendedContentInterface } from '@shared/interfaces/content/recommended-content/recommended-content'
import { isDefined } from '@utils/connascence'
import { merge } from 'lodash'

export const getContentPendingReducer = (state: ContentsState): void => {
  state.fetchStatus = 'loading'
  state.errorMessages = ''
}

export const setButtonStateReducer = (
  state: ContentsState,
  {
    payload: { contentId, buttonState },
  }: {
    payload: { contentId: string; buttonState: UserContentState }
  },
): void => {
  // Update the state.byContentId[contentId].state
  // For example, update this state to expired or watchNow after the timer time out
  state.byContentId = {
    ...state.byContentId,
    [contentId]: {
      ...state.byContentId?.[contentId],
      state: { button: buttonState, access: { expiresIn: null, expired: false } },
    },
  }
}

export const getContentFulfilledReducer = (
  state: ContentsState,
  { payload: content }: { payload: ContentEntity },
): void => {
  // has already data into the state?
  const _content = merge(
    {}, // Create a new object
    state.byContentId[content.contentId], // existing data
    content, // new data
  )

  state.byContentId = {
    ...state.byContentId,
    [content.contentId]: _content,
  }

  // Only on courses!
  if (content.contentType === 'bundle') {
    const isCourse = content?.bundle?.bundleType?.typeName === 'course'

    if (isCourse) {
      const contents: ContentEntity[] = []

      content.bundle.bundleModules.forEach((module) => {
        if (!isEmptyArray(module.moduleContents)) {
          module.moduleContents.forEach((moduleContent) => {
            contents.push(moduleContent.content)
          })
        }
      })
      state.byContentId = {
        ...state.byContentId,
        ...convertArrayToObject<ContentEntity>(contents, 'contentId'),
      }
    }
  }

  /**
   * Adding content data that is coming from the bundle part, into the state.
   * We attach the content data only if the content doesn't exist.
   */
  if (content?.bundles) {
    content?.bundles.map((bundle) => {
      if (isDefined(bundle?.content)) {
        const { content } = bundle
        if (!isDefined(state.byContentId[content.contentId])) {
          state.byContentId[content.contentId] = content
        }
      }
    })
  }

  state.fetchStatus = 'succeeded'
  state.errorMessages = ''
}

export const getFeaturedContentByChannelIdFulfilledReducer = (
  state: ContentsState,
  { payload: { featuredContents, channelId } }: { payload: FeaturedContentReducerInterface },
): void => {
  state.featuredContentByChanelId = {
    ...state.featuredContentByChanelId,
    [channelId]: featuredContents,
  }
}

export const getContentRejectedReducer = (
  state: ContentsState,
  { error }: { error: { message: string } },
): void => {
  state.fetchStatus = 'failed'
  state.errorMessages = error.message
}

export const saveRailsContentsReducer = (
  state: ContentsState,
  { payload: { rails } }: { payload: RailsDictionaryEntry },
): void => {
  const getContentsArray = pipe<RailEntity[], RailContent[], ContentEntity[]>(
    prop<'railContents', RailContent[]>('railContents'),
    map(prop('content')),
  )
  const newContents = pipe(
    map(getContentsArray),
    flatten,
    filter((content) => content !== null),
  )(rails)

  state.byContentId = {
    ...state.byContentId,
    ...convertArrayToObject<ContentEntity>(newContents, 'contentId'),
  }
}

/************************************* Get current progress ********************************/
/**
 * Get Resume Playback Pending Reducer
 * @param state
 */
export const getResumePlaybackPendingReducer = (state: ContentsState): void => {
  state.fetchPlaybackStatus = 'loading'
  state.errorMessages = ''
}

/**
 * Get Resume Playback Fulfilled Reducer
 * @param state
 * @param resumePlayback
 */
export const getResumePlaybackFulfilledReducer = (
  state: ContentsState,
  { payload: resumePlayback }: { payload: ResumePlayback },
): void => {
  if (resumePlayback) {
    // Filter out any resume playback for the current contentId
    const filteredContentResumePlayback = state.contentResumePlayback.filter(
      (contentPlayback) => contentPlayback?.contentId !== resumePlayback.contentId,
    )

    // Append the resume playback to the content resume playback state
    state.contentResumePlayback = [...filteredContentResumePlayback, resumePlayback]
    state.fetchPlaybackStatus = 'succeeded'
  } else {
    state.fetchPlaybackStatus = 'failed'
  }
}

export const getResumePlaybackByIdsFulfilledReducer = (
  state: ContentsState,
  { payload: resumePlaybacks }: { payload: ResumePlayback[] },
): void => {
  if (!isEmptyArray(resumePlaybacks)) {
    const filteredContentResumePlayback = state.contentResumePlayback.filter((contentPlayback) => {
      return resumePlaybacks.find(
        (resumePlayback) => contentPlayback?.contentId !== resumePlayback.contentId,
      )
    })

    // Append the resume playback to the content resume playback state
    state.contentResumePlayback = [...filteredContentResumePlayback, ...resumePlaybacks]
  }
}

export const getContentResourcesFulfilledReducer = (
  state: ContentsState,
  { payload: contentResources }: { payload: ContentResourceInterface[] },
): void => {
  if (!isEmptyArray(contentResources)) {
    // Filter out any content resources for the current contentId
    const filteredContentResources = state.contentResources.filter(
      (contentResource) => contentResource.contentId !== contentResources[0].contentId,
    )

    // Append the new content resources to the content resource state
    state.contentResources = [...filteredContentResources, ...contentResources]
  }
}

export const getRecommendedContentFulfilledReducer = (
  state: ContentsState,
  {
    payload: recommendedContentPayload,
  }: {
    payload: {
      contentId: string
      recommended: RecommendedContentInterface[]
    }
  },
): void => {
  if (recommendedContentPayload) {
    state.recommendedContentByContentId = {
      ...state.recommendedContentByContentId,
      [recommendedContentPayload.contentId]: recommendedContentPayload.recommended,
    }
  }
}
