import { useRouter } from 'next/router'
import useIsUserLogged from '@hooks/_user/use-is-user-logged'
import { useEffect, useState } from 'react'
import { getUserBillingActivePlansThunk } from '@store/user-billing-slice/active-plans/_thunks'
import { getUserBillingPurchasesThunk } from '@store/user-billing-slice/purchases/_thunks'
import { useDispatch } from 'react-redux'
import { VideoReviewAccessEnum } from '@shared/enums/video-review-access.enum'
import fbAuth from '@apis/firebase'

export default function useFetchUserPurchases() {
  const router = useRouter()
  const dispatch = useDispatch()
  const { isLogged, isFBLoading } = useIsUserLogged()

  const [access, setAccess] = useState<VideoReviewAccessEnum>(VideoReviewAccessEnum.LOADING)

  useEffect(() => {
    if (router.isReady && !isFBLoading && isLogged) {
      dispatch(getUserBillingActivePlansThunk())
      dispatch(getUserBillingPurchasesThunk())
      setAccess(VideoReviewAccessEnum.ACCESS)
    }
  }, [dispatch, router, isFBLoading, isLogged])

  useEffect(() => {
    if (router.isReady) {
      fbAuth.onAuthStateChanged(async (user) => {
        if (!user) {
          setAccess(VideoReviewAccessEnum.NO_ACCESS)
        }
      })
    }
  }, [router])

  return access
}
